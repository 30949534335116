<template>
  <v-card tile>
    <v-toolbar elevation="1" dense>
      <v-toolbar-title>
        <span class="text-caption" v-text="$attrs.title" />
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn icon @click.prevent="show_info = !show_info">
          <v-icon v-text="`mdi-chevron-${show_info ? 'up' : 'down'}`" />
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-spacer />
    <v-card-text v-if="show_info">
      <div v-if="$attrs.data.length > 0">
        <v-row v-for="(v, i) in $attrs.data" :key="i">
          <v-col cols="12" v-if="v.active && v.rs_service_type_id == 1 && !v.agreement">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="`${i + 1}. CONSULTA`" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-alert
                    v-if="!particular"
                    :type="v.letter_generated ? 'success' : 'warning'"
                    dense
                  >
                    <v-row align="center">
                      <v-col
                        class="text-center"
                        v-text="
                          `CARTA${v.letter_generated ? '' : ' NO'} GENERADA`
                        "
                      />
                    </v-row>
                  </v-alert>
                </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row v-if="v.doctor" dense>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="Médico"
                      :value="`${v.doctor.name} ${v.doctor.first_surname} ${v.doctor.second_surname}`"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="6">
                    <ViewData
                      label="Contacto"
                      :value="`${
                        v.doctor.phone ? `TEL. ${v.doctor.phone} / ` : ''
                      }${
                        v.doctor.cell_phone
                          ? `CEL. ${v.doctor.cell_phone} / `
                          : ''
                      }${v.doctor.email ? v.doctor.email : ''}`"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="Monto"
                      :value="numberFormat(v.benefits[0].amount)"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="Dirección"
                      :value="
                        v.consulting_room
                          ? `${
                              v.consulting_room.street
                                ? v.consulting_room.street
                                : ''
                            }${
                              v.consulting_room.external_number
                                ? ` ${v.consulting_room.external_number}`
                                : ''
                            }${
                              v.consulting_room.internal_number
                                ? `, INT. ${v.consulting_room.internal_number}`
                                : ''
                            }${
                              v.consulting_room.suburb
                                ? `, COL. ${v.consulting_room.suburb}`
                                : ''
                            }`
                          : null
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="Municipio"
                      :value="
                        v.consulting_room
                          ? v.consulting_room.municipality_name
                          : null
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="Estado"
                      :value="
                        v.consulting_room ? v.consulting_room.entity_name : null
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="Código postal"
                      :value="v.consulting_room ? v.consulting_room.zip : null"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      :label="'Especialidad'"
                      :value="
                        v.doctor_specialty ? v.doctor_specialty.name : null
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="Cédula"
                      :value="
                        v.doctor_specialty ? v.doctor_specialty.license : null
                      "
                    />
                  </v-col>
                  <v-col v-if="!particular" cols="12" class="text-right">
                    <v-btn
                      v-if="
                        login.role_id != 7 &&
                        login.role_id != 8 &&
                        !v.refused &&
                        v.rs_service_type_id === 1 &&
                        v.confirm_consultation &&
                        !v.confirm_consultation_by_id
                      "
                      color="warning"
                      dark
                      @click="consultationConfirmDialog(v, i)"
                    >
                      ACEPTAR CARTAS
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="v.active && v.rs_service_type_id == 2">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="`${i + 1}. PROVEEDOR`" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-alert
                    :type="v.letter_generated ? 'success' : 'warning'"
                    dense
                  >
                    <v-row align="center">
                      <v-col
                        class="text-center"
                        v-text="
                          `CARTA${v.letter_generated ? '' : ' NO'} GENERADA`
                        "
                      />
                    </v-row>
                  </v-alert>
                </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col v-if="v.provider" cols="12">
                    <v-row dense>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          label="Tipo de proveedor"
                          :value="v.provider.provider_type.provider_type"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData label="Nombre" :value="v.provider.name" />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <ViewData
                          label="Nombre de comercial"
                          :value="v.provider.trade_name"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          label="Dirección"
                          :value="`${
                            v.provider.street ? v.provider.street : ''
                          }${
                            v.provider.external_number
                              ? ` ${v.provider.external_number}`
                              : ''
                          }${
                            v.provider.internal_number
                              ? `, INT. ${v.provider.internal_number}`
                              : ''
                          }${
                            v.provider.suburb
                              ? `, COL. ${v.provider.suburb}`
                              : ''
                          }`"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          label="Municipio"
                          :value="v.provider.municipality"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData label="Estado" :value="v.provider.entity" />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          label="Código postal"
                          :value="v.provider.zip"
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <span
                      class="text-caption font-weight-bold"
                      v-text="'Prestaciones'"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-row dense>
                      <v-col cols="12" v-if="v.benefits.length > 0">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th v-text="'#'" />
                                <th v-text="'Código'" />
                                <th v-text="'Descripción'" />
                                <th v-text="'Cobertura'" />
                                <th v-text="'Monto'" />
                                <th v-text="''" />
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(vb, k) in v.benefits"
                                :key="k"
                                v-if="vb.active"
                              >
                                <td v-text="`${k + 1}`" />
                                <td v-text="vb.code" />
                                <td v-text="vb.description" />
                                <td
                                  :class="
                                    vb.revision
                                      ? 'orange--text text--darken-3'
                                      : ''
                                  "
                                  v-text="
                                    vb.coverage_type ? vb.coverage_type : 'N/A'
                                  "
                                />
                                <td v-text="numberFormat(vb.amount)" />
                                <td></td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>

                        <div class="text-center">
                          <small v-text="'Monto Total'" />
                          <p
                            class="text-h6"
                            v-text="numberFormat(v.benefits_amount)"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" v-else>
                        <p class="text-caption">
                          Ningúna prestación ha sido agregada
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            v-if="
              v.active &&
              v.agreement &&
              !v.provider &&
              !v.doctor &&
              !v.consulting_room
            "
          >
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span
                    class="text-caption"
                    v-text="
                      `${i + 1}. CONVENIO (${
                        v.type === 1 ? 'CONSULTA' : 'PROVEEDOR'
                      })`
                    "
                  />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-alert
                    :type="v.letter_generated ? 'success' : 'warning'"
                    dense
                  >
                    <v-row align="center">
                      <v-col
                        class="text-center"
                        v-text="
                          `CARTA${v.letter_generated ? '' : ' NO'} GENERADA`
                        "
                      />
                    </v-row>
                  </v-alert>
                </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-alert type="warning" dense>
                      <v-row align="center">
                        <v-col class="text-center">
                          No se encontró
                          {{ v.type === 1 ? "médico" : "proveedor" }}
                          para est{{
                            v.type === 1 ? "a consulta" : "e servicio"
                          }}
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" xs="12" md="3" v-if="v.type === 1">
                    <ViewData
                      label="Tipo de especialidad "
                      :value="v.specialty_type.specialty_type"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" v-if="v.type === 2">
                    <ViewData
                      label="Tipo de proveedor"
                      :value="v.provider_type.provider_type"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="Municipio"
                      :value="
                        v.municipality_id === null
                          ? 'TODOS'
                          : v.municipality.municipality
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData label="Estado" :value="v.entity.entity" />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData label="Código postal" :value="v.zip" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="v.url_survey" cols="12" class="text-right">
            <v-btn
              color="success"
              dark
              small
              :href="url_documents + '/rs_services_surveys/' + v.url_survey"
              target="_blank"
            >
              <v-icon left small> mdi-file-table </v-icon>
              ENCUESTA
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-else>No selecciono ningún servicio.</div>
    </v-card-text>

    <v-dialog
      v-model="consultation_confirm_dialog"
      scrollable
      persistent
      max-width="800px"
    >
      <v-card
        tile
        :loading="consultation_confirm_dialog_loading"
        :disabled="consultation_confirm_dialog_loading"
      >
        <v-toolbar dark>
          <v-toolbar-title v-text="'Confirmar consulta'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="consultation_confirm_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="consultation_confirm_dialog">
          <v-form
            v-on:submit.prevent
            ref="form_consultation_confirm"
            lazy-validation
          >
            <v-row class="mt-3">
              <v-col cols="12" xs="12" md="12">
                <v-text-field
                  label="Nombre de con quien se hablo*"
                  v-model="confirm_consultation_name"
                  maxlength="75"
                  type="text"
                  dense
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" xs="12" md="4">
                <DatePicker
                  label="Fecha*"
                  :model.sync="confirm_consultation_date"
                  clean
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" xs="12" md="4">
                <v-text-field
                  v-model="confirm_consultation_time"
                  label="Hora*"
                  type="time"
                  dense
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" xs="12" md="4">
                <v-text-field
                  v-model="confirm_consultation_amount"
                  label="Monto*"
                  type="number"
                  dense
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" xs="12" md="6">
                <v-btn
                  color="error"
                  block
                  @click.prevent="consultationConfirm(false)"
                >
                  NO
                </v-btn>
              </v-col>
              <v-col cols="12" xs="12" md="6">
                <v-btn
                  color="success"
                  block
                  @click.prevent="consultationConfirm(true)"
                >
                  SI
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  msgConfirm,
  msgAlert,
  headersToken,
  URL_API,
  rules,
  URL_DOCUMENTS,
} from "../control";
import ViewData from "../components/ViewData.vue";
import DatePicker from "../components/DatePicker.vue";

export default {
  components: {
    ViewData,
    DatePicker,
  },
  data() {
    return {
      url_documents: URL_DOCUMENTS,
      show_info: true,
      login: this.$store.getters.getLogin,
      rules: rules(),
      data: null,
      position: null,
      consultation_confirm_dialog: false,
      consultation_confirm_dialog_loading: false,
      confirm_consultation_name: null,
      confirm_consultation_date: null,
      confirm_consultation_time: null,
      confirm_consultation_amount: null,
      particular: typeof this.$attrs.particular !== "undefined" ? true : false,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    consultationConfirmDialog(v, i) {
      this.data = v;
      this.position = i;
      this.consultation_confirm_dialog = true;
      this.consultation_confirm_dialog_loading = false;
      this.confirm_consultation_name = null;
      this.confirm_consultation_date = null;
      this.confirm_consultation_time = null;
      this.confirm_consultation_amount = null;
    },
    consultationConfirm(response_confirm_consultation) {
      if (this.$refs.form_consultation_confirm.validate()) {
        this.$swal.fire(msgConfirm(`¿Confirma la consulta?`)).then((res) => {
          if (res.isConfirmed) {
            this.consultation_confirm_dialog_loading = true;

            Axios.post(
              URL_API + "/rss/confirm/consultation",
              {
                rs_service_id: this.data.id,
                confirm_consultation_by_id: this.login.id,
                confirm_consultation_name: this.confirm_consultation_name,
                confirm_consultation_date:
                  this.confirm_consultation_date +
                  " " +
                  this.confirm_consultation_time,
                confirm_consultation_amount: this.confirm_consultation_amount,
                response_confirm_consultation: response_confirm_consultation,
              },
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.consultation_confirm_dialog = false;
                this.$attrs.data[this.position].confirm_consultation_by_id =
                  this.login.id;
              } else {
                console.log(res.data.message);
              }
            });
          }
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    if (this.particular) {
      this.show_info = true;
    }
  },
};
</script>